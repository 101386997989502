<template>
  <div class="w-full">
    <div class="flex-shrink-0 p-4 border-b border-gray-100 flex flex-col gap-4">
      <fw-heading>{{ $t('document_translations') }}</fw-heading>
      <div v-if="canTranslate" class="flex gap-3">
        <fw-button class="flex-1" type="xlight" @click.native="$emit('new-language')">
          {{ $t('translate') }}
        </fw-button>
      </div>
    </div>
    <div class="p-4 flex flex-col gap-2">
      <RecordTranslation
        v-for="version in versions"
        :key="version.key"
        :active="currentVersion && currentVersion.key === version.key"
        :version="version"
        class="cursor-pointer"
        @click.native="changeVersion(version)"
      />
    </div>
  </div>
</template>
<script>
import RecordTranslation from '../records/RecordTranslation.vue'
export default {
  name: 'PanelSideVersions',
  components: {
    RecordTranslation,
  },
  props: {
    versions: {
      type: Array,
      required: true,
    },
    currentVersion: {
      type: Object,
    },
    myrole: {
      type: String,
      default: null,
    },
  },
  computed: {
    canTranslate() {
      return this.myrole === 'owner' || this.myrole === 'manager'
    },
  },
  methods: {
    createVersion() {
      this.$emit('create-version')
    },
    changeVersion(version) {
      this.$emit('change-version', version)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "document_translations": "Document translations",
    "translate": "Create new translation"
  },
  "pt": {
    "document_translations": "Traduções do documento",
    "translate": "Criar nova tradução"
  }
}
</i18n>
